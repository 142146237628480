/*!
 * Trimble's Modus Framework layout
 * Application layout styles for Trimble web applications.
 * Extends Bootstrap
 * Copyright 2019-2024 Trimble Inc.
 *
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/
/**
 *  Modus Light Theme Variables
 *
 *  These variables are specific to the Light Theme of Modus
 */
/**
 *  Modus Variables
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *
 *  Theme specific variables (light vs dark) are defined in _light-theme.scss
 *  and _dark-theme.scss
 */
/**
 * Modus Mixins
 */
/**
 *  Modus Framework Layout
 *
 *  Application layout specific styles that are compiled seprately.
 *
 *  Layout specific variables are contained within this file.
 */
html,
body {
  width: 100%;
  height: 100%; }

.no-select, .modus-header, .modus-body .modus-sidebar {
  -webkit-touch-callout: none;
  user-select: none; }

.modus-layout {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  overflow: hidden; }

.modus-header {
  height: 56px;
  z-index: 999;
  border-bottom: 1px solid #cbcdd6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1); }
  .modus-header .menu-btn {
    font-size: 2rem;
    padding-right: 16px;
    cursor: pointer; }

.modus-body {
  display: flex;
  flex: 2;
  overflow-y: auto; }
  .modus-body .modus-sidebar {
    flex: 0 0 60px;
    order: -1;
    width: 60px;
    z-index: 998;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    border-right: 1px solid #cbcdd6;
    background-color: #fff;
    transition: flex-basis 0.2s cubic-bezier(0.57, 0.1, 0.73, 1.28);
    box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.1); }
    .modus-body .modus-sidebar .nav-link {
      display: flex;
      height: 3rem;
      padding: 0 10px 0 0;
      align-items: center;
      color: #353a40;
      font-size: 16px; }
      .modus-body .modus-sidebar .nav-link:hover {
        background-color: #e0e1e9; }
      .modus-body .modus-sidebar .nav-link.active {
        background-color: #217cbb;
        color: #fff; }
      .modus-body .modus-sidebar .nav-link.disabled {
        opacity: 0.3; }
      .modus-body .modus-sidebar .nav-link .left-nav-icon {
        display: inline-block;
        height: 1.5rem;
        padding: 0 18px;
        justify-self: center; }
        .modus-body .modus-sidebar .nav-link .left-nav-icon .modus-icon, .modus-body .modus-sidebar .nav-link .left-nav-icon .modus-icons {
          font-size: 1.5rem;
          line-height: 1; }
        .modus-body .modus-sidebar .nav-link .left-nav-icon img {
          max-width: 100%;
          height: 24px; }

.modus-footer {
  flex-wrap: wrap;
  z-index: 9999;
  height: 24px;
  padding: 2px 10px;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #cbcdd6; }

.modus-content-rows {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  position: relative; }

.modus-content-columns {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  position: relative;
  min-height: 0; }

.modus-content {
  flex: 1 1 100%;
  max-height: 100%;
  position: relative;
  overflow-y: auto;
  background-color: #f1f1f6; }

.modus-toolbar {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  min-height: 48px;
  max-height: calc(48px * 2);
  border-bottom: 1px solid #cbcdd6;
  padding: 0 16px; }

.sidebar-open > .modus-sidebar ul {
  padding: 0; }
  .sidebar-open > .modus-sidebar ul li {
    list-style: none; }
    .sidebar-open > .modus-sidebar ul li .collapsed .expand-icon {
      transform: rotate(180deg); }
  .sidebar-open > .modus-sidebar ul .sub-nav .nav-link {
    border: none;
    padding-left: 3rem; }

.sidebar-closed > .modus-sidebar {
  flex: 0 0 60px; }
  .sidebar-closed > .modus-sidebar ul {
    padding: 0; }
    .sidebar-closed > .modus-sidebar ul li {
      list-style: none; }
      .sidebar-closed > .modus-sidebar ul li .sub-nav {
        display: none; }
      .sidebar-closed > .modus-sidebar ul li.has-subnav:hover .sub-nav {
        display: block;
        position: absolute;
        left: 60px;
        margin-top: -48px; }
        .sidebar-closed > .modus-sidebar ul li.has-subnav:hover .sub-nav .nav-link {
          padding-right: 1rem; }

.modus-panel {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-height: 100%;
  position: relative;
  z-index: 500;
  background-color: #f1f1f6; }
  .modus-panel.panel-sm {
    min-width: 256px;
    max-width: 256px; }
  .modus-panel.panel-md {
    min-width: 352px;
    max-width: 352px; }
  .modus-panel.panel-lg {
    min-width: 448px;
    max-width: 448px; }
  .modus-panel.panel-xl {
    min-width: 544px;
    max-width: 544px; }
  .modus-panel .panel-header {
    display: flex;
    flex: 0 0 48px;
    z-index: 600;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #cbcdd6; }
    .modus-panel .panel-header h1,
    .modus-panel .panel-header h2,
    .modus-panel .panel-header h3,
    .modus-panel .panel-header h4,
    .modus-panel .panel-header h5,
    .modus-panel .panel-header h6 {
      text-align: center;
      margin: 0 !important; }
  .modus-panel .panel-body {
    display: flex;
    overflow: hidden;
    flex-direction: column; }
    .modus-panel .panel-body .static-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding: 8px;
      border-bottom: 1px solid #cbcdd6; }
    .modus-panel .panel-body .scroll-container {
      overflow-y: auto;
      height: 100%; }
  .modus-panel .panel-footer {
    padding: 0 8px; }

.right-items {
  position: absolute;
  right: 0; }

.left-items {
  position: absolute;
  left: 0; }

@media (min-width: 1050px) {
  .sidebar-open > .modus-sidebar {
    flex: 0 0 300px; } }

@media (max-width: 1050px) {
  .sidebar-open > .modus-sidebar {
    position: absolute;
    height: calc(100% - 56px);
    width: 300px; }
  .sidebar-open .modus-main-content {
    margin-left: 60px; } }
